/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { useState, useEffect } from 'react'
import styles from './header.module.css'
import Link from 'next/link'
import utils from './utils'
import consts from './consts'

import { useStateValue } from '../state';
import Circle from './circle'
import StyledToolTip from './styledToolTip'
import UserSettings from './userSettings'
export default function Header({headerStyle, isCustomizeModeActive, linkStyle, isCustomView,
  hideSignupLink, showOnlyLogo, disableLogoLink}) {
  const [isLoggedIn, updateIsLoggedIn] = useState(false);
  const [isLandingPage, updateIsLandingPage] = useState(false);
  const [
    {user, showUserSettingsTooltip},
    dispatch,
  ] = useStateValue()


useEffect(() => {
  let cachedValue = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.IS_LOGGED_IN);
  updateIsLoggedIn(cachedValue);
  let isLandingPage = window.location.href.includes('landing');
  updateIsLandingPage(isLandingPage);
  }, [])

  function onExitBrandEditorClick(linkType) {
    if(isCustomizeModeActive) { //existing the brand editor page through the header links
      utils.trackEvent(consts.TRACKING_CATEGORY.CUSTOM_BRAND_EDITOR, 'exit-brand-editor','link-type', linkType);
    }
    //utils.clearTransferLocalStorage();
  }

    return (
      <React.Fragment>
        {!showOnlyLogo && <div style={headerStyle} className={!isCustomizeModeActive ? styles.nav : styles.navHidden}> 
          <Link href="/posts/about">
            <a target='_blank' style={linkStyle} className={styles.linkStyle}>About</a>
          </Link>
            {(!isLoggedIn || isLandingPage) && <Link href="/posts/pricing/?source=header">
            <a target='_blank' style={linkStyle} className={styles.linkStyle}>Pricing</a>
         </Link>}
        <Link href="/posts/faq">
            <a target='_blank' style={linkStyle} className={styles.linkStyle}>FAQ</a>
         </Link>
         <Link href="/posts/login">
         <a style={linkStyle} className={isLoggedIn ? styles.hideLink : styles.linkStyle}>{'Log in'}</a>
         </Link>
        { (isLoggedIn && user.deviceType === consts.DEVICE_TYPES.DESKTOP) && <Circle onClick={() => dispatch({ type: 'SHOW-USER-SETTINGS-CHANGED', payload: !showUserSettingsTooltip})} color={'#F8746B'} width={36} height={36} text={user && user.userName && user.userName.substring(0,1).toUpperCase()} circleStyle={{marginRight:'12px', cursor: 'pointer', position: 'relative', top: '15px', zIndex: '100'}}/>}
         <StyledToolTip tooltipStyle={{position: 'absolute', top: '80px', width: '284px', minHeight: '289px'}} pointerClassName={'upwardsPointer'}
         show={showUserSettingsTooltip}
        shouldRenderPointer={true} fadeOut={false}>
           <UserSettings/>
         </StyledToolTip>
        {!(isLoggedIn || user.deviceType === 'mobile') && !hideSignupLink && false ? <Link href={"/posts/signup/?source=header"}>
         <a style={linkStyle} target="_blank"
          rel="noopener noreferrer" className={isCustomView ? styles.genericAddYourBrand : styles.addYourBrand}>Add your brand</a>
         </Link> : null}
         </div>}
         <div className={styles.logoWrapper}>
         <a onClick={() => onExitBrandEditorClick('logo')} href="/" className={isCustomView ? styles.genericLogo : !isCustomizeModeActive ? styles.logo : styles.previewModeLogo}>FileDriver</a>
         {isCustomizeModeActive && <a onClick={() => onExitBrandEditorClick('drive-more-file')} style={{textDecoration: 'none'}} href={!disableLogoLink && "/"} className={styles.driveMoreFileLink}>Drive More Files</a>}
         </div>
      </React.Fragment>
    )
  }