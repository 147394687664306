/* eslint-disable react/react-in-jsx-scope */
export default function CloseIcon({onClick, iconStyle, svgStyle})  {
    return (
        <div id='iconWrapper' style={iconStyle}>
        <svg style={svgStyle} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M17 6.20857L15.7914 5L11 9.79143L6.20857 5L5 6.20857L9.79143 11L5 15.7914L6.20857 17L11 12.2086L15.7914 17L17 15.7914L12.2086 11L17 6.20857Z" fill="black"/>
        </svg>
        </div>
    );
};
