/* eslint-disable react/react-in-jsx-scope */
import styles from './layout.module.css'
import Head from 'next/head'

export default function Layout({ children, hide, isCustomizeModeActive, extendedStyle, isCustomView}) {
  return (
    <div id='layout' style={{...extendedStyle, display: hide ? "none" : extendedStyle ? extendedStyle.display : 'block'}} className={isCustomView ? styles.genericContainer : !isCustomizeModeActive ? styles.container : styles.customizeContainer}>
      <Head>
      <title>FileDriver.com Share Your Files, Grow Your Brand</title>
        <meta
          name="description"
          content="Send large files with zero friction and professional branded pages. Start now for free "
        />
        <meta charSet="UTF-8"/>
        <meta name="og:title" content='FileDriver.com' />
        <meta name="google-site-verification" content="frbDasBu5b7MI-5vDASWNQ2AwDX9gmjau1dl4g1Qp4Q" />
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-PCKDZZZ"></script>
        <script src="https://www.google.com/recaptcha/api.js?render=6Ld2MAYaAAAAAIjgG4yWizwpqFGO0fgAhq4ljRAH"></script>
        <script src="https://raw.githubusercontent.com/webrtc/adapter/master/release/adapter.js"></script>  
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        ga('create', 'UA-182180388-1', 'auto');
        ga('send', 'pageview');
       `}} />
        <script async src='https://www.google-analytics.com/analytics.js'></script>
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
        p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments) };
        p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
        n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//storage.googleapis.com/filedriver-sp-js/gh7rnghq.js","snowplow"));
        snowplow("newTracker", "sp", "tracker.filedriver.com", {
          appId: "fileDriver",
          cookieDomain: '.filedriver.com',
          eventMethod: 'beacon',
          platform: "web",
          sessionCookieTimeout: 28800,
          contexts: {
            webPage: true,
            performanceTiming: true
          }
        });
        snowplow('enableActivityTracking', 30, 10);
        snowplow('trackPageView');
       `}} />
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2080259,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-0QDV9SYX45"></script>
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-0QDV9SYX45');
        gtag('config', 'AW-725015658');
        gtag('config', 'UA-182180388-1');
        `}} />
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        function gtag_report_conversion(url) {
          var callback = function () {
            if (typeof(url) != 'undefined') {
              window.location = url;
            }
          };
          gtag('event', 'conversion', {
              'send_to': 'AW-725015658/oBK-CLiAiegBEOq429kC',
              'event_callback': callback
          });
          return false;
        }`}} />
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        function gtag_report_engaged_user_conversion(url) {
          var callback = function () {
            if (typeof(url) != 'undefined') {
             window.location = url;
           }
         };
         gtag('event', 'conversion', {
             'send_to': 'AW-725015658/dFzeCJ6y-usBEOq429kC',
             'event_callback': callback
         });
         return false;
       }`}} />
        <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        function gtag_report_answered_work_conversion(url) {
          var callback = function () {
            if (typeof(url) != 'undefined') {
              window.location = url;
            }
          };
          gtag('event', 'conversion', {
              'send_to': 'AW-725015658/cc1nCIGVgOwBEOq429kC',
              'event_callback': callback
          });
          return false;
        }`}} />
       <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        function gtag_report_signup(url) {
          var callback = function () {
            if (typeof(url) != 'undefined') {
              window.location = url;
            }
          };
          gtag('event', 'conversion', {
              'send_to': 'AW-725015658/xXzcCMf5__IBEOq429kC',
              'event_callback': callback
          });
          return false;
        }`}} />
      <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
       function gtag_report_signup_visit(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-725015658/xvzSCOfZ3IkCEOq429kC',
            'event_callback': callback
        });
        return false;
      }`}} />
      <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
       function gtag_report_extension_installed(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-725015658/r8yHCKKU188CEOq429kC',
            'event_callback': callback
        });
        return false;
      }`}} />
      <script type="text/javascript" async dangerouslySetInnerHTML={{ __html: `
        (function(l,e,a,p) {
          window.UserLeap = function(){U._queue.push(arguments)}
          var U = window.UserLeap;U.appId = a;U._queue = [];
          a=l.createElement('script');
          a.async=1;a.src=e+'?id='+U.appId;
          p=l.getElementsByTagName('script')[0];
          p.parentNode.insertBefore(a, p);
        })(document, 'https://cdn.userleap.com/shim.js', 'NDJn908Yf0');
     `}} />
      </Head>
      {children}
    </div>
  )
}