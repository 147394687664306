/* eslint-disable react/react-in-jsx-scope */
import style from './selectionChip.module.css'
import {useState} from 'react'

export default function SelectionChip({text, onClick, isClicked, chipStyle})  {
  const handleOnClick = (e) => {
    onClick({text});
    e.preventDefault();
    e.stopPropagation();
}
    return (
      <button style={chipStyle} onClick = {handleOnClick} className={!isClicked ? style.chip : style.chipClicked}>
        <div className={style.chipContent}>
          <div>
          {text} 
          </div>
        </div>
      </button>
    );
};