/* eslint-disable react/react-in-jsx-scope */
import {useEffect} from 'react'
import style from './styledToolTip.module.css'

export default function StyledToolTip({children, show, onInit, shouldRenderPointer, fadeOut, tooltipStyle, pointerClassName, pointerStyle, tooltipContentStyle})  {

      const renderPointer = () => {
        return (
          <svg style={pointerStyle} className={pointerClassName ? style[pointerClassName] : style.pointer} xmlns="http://www.w3.org/2000/svg" width="121" height="142" viewBox="0 0 121 142" fill="none">
          <g filter="url(#filter0_d)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M70.1716 35L70.1716 77L50.5858 57.4142C49.8047 56.6332 49.8047 55.3668 50.5858 54.5858L70.1716 35Z" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_d" x="0" y="0" width="120.172" height="142" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="15"/>
          <feGaussianBlur stdDeviation="25"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
        );
      }

      useEffect(() => {
        if(show) {
         onInit && onInit();
        } 
      }, [show])

      return (
      show &&  <div style={tooltipStyle} className={!fadeOut ? style.container : style.containerFadeOut}>
          {shouldRenderPointer && renderPointer()}
          <div style={tooltipContentStyle} className={style.toolTipContent}>
          {children}
          </div>
        </div>
      );
};