/* eslint-disable react/react-in-jsx-scope */
import style from './circle.module.css'

export default function Circle({color, width, height, text, textColor, circleStyle, onClick, circleRef})  {
    return (
    <svg ref={circleRef} onClick={onClick} style={circleStyle} className={style.circle} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 19" fill="none" tabIndex={0}>
    <circle cx="9.5" cy="9.5" r="9.5" fill={color}/>
    {text && <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fill={textColor || '#ffff'} fontSize="0.4em">{text}</text>}
    </svg>
    );
};