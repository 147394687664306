
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { useEffect, useState, useRef } from 'react';
import Circle from './circle';
import SelectionChip from './selectionChip';
import style from './userSettings.module.css'
import restUtils from './restUtils'
import ExternalLinkIcon from './externalLinkIcon'
import utils from './utils'
import consts from './consts'
import CloseIcon from './closeIcon';
import { useGoogleLogout } from 'react-google-login'

import { useStateValue } from '../state';

export default function UserSettings()  {
  const [showCancelConfirmation, updateShowCancelConfirmation] = useState(false);
  const [cancelConfirmed, updateCancelConfirmed] = useState(false);

  const wrapperRef = useRef(null);
  const { signOut } = useGoogleLogout({
    clientId: process.env.GOOGLE_CLIENT_ID,
    //onFailure: onGoogleLogoutFailure,
    //onLogoutSuccess: onGoogleLogoutSuccess
  })

  const [
    {user},
    dispatch,
  ] = useStateValue()

  useEffect(() => {
    async function cancelSubscription() {
      if(user.trialInfo.onTrial) {
        utils.trackEvent(consts.TRACKING_CATEGORY.PRICING, 'cancel_trial','signup_id',user.userId);
        await restUtils.post('cancelTrial', null, true);
      } else {
        utils.trackEvent(consts.TRACKING_CATEGORY.PRICING, 'cancel_subscription','signup_id',user.userId);
        await restUtils.post('cancelSubscription', null, true);
      }
      signOut();
      window.location.assign('/posts/logout');
    }
    if(cancelConfirmed) {
        cancelSubscription();
    }
  }, [cancelConfirmed])
  
  useEffect(() => {
    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          dispatch({ type: 'SHOW-USER-SETTINGS-CHANGED', payload: false})
        }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [wrapperRef]);

  function onLogoutClick() {
    signOut();
    window.location.assign('/posts/logout');
  }

  function renderCompletionIcon() {
    return (
      <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.5 66C51.4493 66 66 51.4493 66 33.5C66 15.5507 51.4493 1 33.5 1C15.5507 1 1 15.5507 1 33.5C1 51.4493 15.5507 66 33.5 66Z" stroke="#13BCB4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23 33.5L30 41L44 26" stroke="#13BCB4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }

  function renderCancellationConfirmed() {
    return (
    <div className={style.cancellationConfirmed}>
      {renderCompletionIcon()}
      <span className={style.cancellationConfirmedText}>Cancellation <br/> Confirmed</span>
    </div>
    );
  }

  function renderCancelConfirmation() {
    return(
      <div className={style.cancelConfirmationContainer}>
      <span className={style.cancelConfirmationText}>Are you sure you want to <br/> cancel your subscription?</span>
      <div className={style.confirmCancelSelectionChips}>
      <SelectionChip chipStyle={{width: '107px', height: '39px'}} text = 'No'  onClick = {() => updateShowCancelConfirmation(false)}/>
      <SelectionChip chipStyle={{width: '107px', height: '39px'}} text = 'Yes' onClick = {() =>  updateCancelConfirmed(true)}/>
     </div> 
     </div>
    );
  }

  function renderUpsellLink() {
    return (
      <a style={{textDecoration: 'none'}}  target="_blank" rel="noopener noreferrer" className={style.ctaLink} href={`/posts/pricing/?source=user-settings-cta`}>
        <div style={{display: 'flex'}}>
        <ExternalLinkIcon styleOverride={{height:'24px', width: '24px', left: '10px', position: 'absolute',
         top: '55%', transform: 'translateY(-50%)'}}/>
         <span className={style.ctaLinkText}>
        Upgrade Plan 
        </span>
        </div>
        </a>
    );
  }
  return (
     <div ref={wrapperRef} className={style.container}>
        {!cancelConfirmed ?  <React.Fragment>
          <CloseIcon onClick={() => dispatch({ type: 'SHOW-USER-SETTINGS-CHANGED', payload: false})} iconStyle={{
          width: "20px", 
          height: "20px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '10px',
          marginRight: '10px',
          zIndex: 10
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>
        <Circle color={'#F8746B'} width={46} height={46} text={user && user.userName && user.userName.substring(0,1).toUpperCase()} circleStyle={{marginRight:'12px', cursor: 'default', position: 'relative', top: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom :'18px'}}/>
      <span className={style.userName}>{user.userName}</span>
        <SelectionChip chipStyle={{width: '131px', height: '45px', marginLeft: '75px'}} text = 'Log out'  onClick = {() =>  onLogoutClick()}/>
        {!showCancelConfirmation ? !user.planInfo.isPaidPlan ? renderUpsellLink() : <span onClick={() => updateShowCancelConfirmation(true)} className={style.cancelSubscription}>Cancel Subscription</span> 
        : renderCancelConfirmation()}
        </React.Fragment> : renderCancellationConfirmed()}
      </div>
  ); 
}